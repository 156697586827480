import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";
import Vuetify from "vuetify/lib";
import {
    VRow,
    VTooltip,
    VLayout,
    VCheckbox,
    VSelect,
    VCol,
    VTextField,
    VSlider,
    VRadio,
    VSwitch,
    VTextarea,
    VFileInput,
    VRating,
    VLabel,
    VCard,
    VSubheader,
    VIcon,
    VList,
    VListItemContent,
    VListItem,
    VListItemTitle,
    VListItemIcon,
    VListItemGroup,
    VListItemSubtitle,
    VToolbar,
    VToolbarTitle,
} from "vuetify/lib";
import { Ripple, Intersect, Touch, Resize, ClickOutside } from "vuetify/lib/directives";
import i18n from "@/i18n";

import "@/sass/overrides.sass";
import "@/sass/custom.sass";

Vue.use(Vuetify);

const darkTheme = {
    primary: "#03a9f4",
    secondary: "#c6d4db",
    accent: "#03a9f4",
    info: "#00CAE3",
    contrast: "#222222",
};
const lightTheme = {
    primary: "#015c85",
    secondary: "#cccccc",
    accent: "#03a9f4",
    info: "#00CAE3",
    contrast: "#eeeeee",
};
export default new Vuetify({
    icons: {
        iconfont: "mdi",
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    theme: {
        themes: {
            dark: darkTheme,
            light: lightTheme,
        },
    },
});
