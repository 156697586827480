import { get, post, patch, del } from "./api.service";

const IssueService = {
    // Status Enum
    // { "o", "Open" },
    // { "a", "Assigned" },
    // { "p", "In Progress" },
    // { "h", "On Hold" },
    // { "r", "Resolved" },
    // { "c", "Closed" },
    // { "d", "Deleted" },

    getIssue(id) {
        return get("/admin/issue", { id });
    },
    addIssue(params) {
        return post("/user/issue", params);
    },
    patchIssue(params) {
        return patch("/admin/issue", params); //patch issue optional fields: description, notes, status, markupAssetId, markupAssetPublicId
    },
    searchIssue(params) {
        return get("/admin/issue/search", params);
    },
    getIssueComment(id) {
        return get("/admin/issue/comment", { id });
    },
    getIssueComments(params) {
        return get("/admin/issue/comment/search", params);
    },
    addIssueComment(params) {
        return post("/admin/issue/comment", params);
    },
    patchIssueComment(params) {
        return patch("/admin/issue/comment", params); //patch comment optional fields: comment, severity, xCoord, yCoord, tags, metaData
    },
    deleteIssueComment(id) {
        return del("/admin/issue/comment", { id });
    },
};

export { IssueService };
